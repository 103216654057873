<template lang="pug">
div
    p.mb-0 {{data.isAdd ? 'Nouveau champ' : 'Modifier le champ'}}
</template>
<script>
export default {
  data() {
    return {
      data: null,
    };
  },
};
</script>
