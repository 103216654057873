<template lang="pug">
div
    .content-loader-center.m-0.h-100(v-if='isChangeTypeField')
        .text-center.flex-center
            .loading-bg-inner 
            .loader.mt-5
                .outer
                .middle
                .inner
            .mt-5
            | {{'Changement du type de champ...'}}
    div(v-else)
        b-row.my-0
            b-col
                b-form-group(label='Type du champ *' label-for='typeField')
                    ejs-dropdownlist(id="typeField" filterType='Contains' v-model='data.typeField' :dataSource="typeFields" :fields="{text:'label',value:'value'}" @change="showFieldTypeElement($event, 'change')")
        b-row.my-0
            b-col
                b-form-group(label='Label du champ *' label-for='label')
                    ejs-textbox(id="label" v-model='data.label')
        b-row.my-0(v-if="showElements.showDescription")
            b-col
                b-form-group(label='Description' label-for='description')
                    ejs-textbox(id="description" v-model='data.description')
        b-row.my-0(v-if="showElements.showOptions")
            b-col
                b-form-group(label="Options (séparées par ';')" label-for='options')
                    ejs-textbox(id="options" v-model='data.options')
        b-row.my-0(v-if="showElements.showParameters")
            b-col
                b-form-group(label="Paramètres" label-for='parameters')
                    ejs-textbox(id="parameters" v-model='data.parameters')
        b-row.my-0(v-if="showElements.showDefaultValue")
            b-col
                b-form-group(label="Valeur par défaut" label-for="defaultValue")       
                    ejs-checkbox(v-if="delayShowElement==11" name="defaultValue" id="defaultValue" v-model="data.defaultValue")
                    ejs-numerictextbox(v-if="delayShowElement==2 || delayShowElement==3 || delayShowElement==1" type="tel" id="defaultValue" :value='0' :format="delayShowElement==2 ? '########.## €' : (delayShowElement==3 ? `#######0.#####' %'` : null)" v-model="data.defaultValue")
                    ejs-textbox(v-else-if="delayShowElement==4 || delayShowElement==5 || delayShowElement==6" :multiline="data.typeField==5 || data.typeField==6" id="defaultValue" v-model="data.defaultValue")
                    ejs-datepicker(v-else-if="delayShowElement==7" id="defaultValue" :format="'dd/MM/yyyy'" locale='fr' placeholder="JJ/MM/AAAA" v-model="data.defaultValue")
                    ejs-datetimepicker(v-else-if="delayShowElement==8" id="defaultValue" locale='fr'  :firstDayOfWeek="1" placeholder="JJ/MM/AAAA HH:MM" v-model="data.defaultValue")
                    ejs-dropdownlist(v-else-if="delayShowElement==9" id="defaultValue" placeholder='Selectionnez une valeur' popupHeight='300px' filterType='Contains' :allowFiltering='true' :dataSource='listOptions' v-model="data.defaultValue" :enabled='enabledDropdown')
                    ejs-multiselect(v-else-if="delayShowElement==10" id="defaultValue" placeholder='Selectionnez une valeur' popupHeight='300px' filterType='Contains' :allowFiltering='true' :dataSource='listOptions' v-model="data.defaultValue" :enabled='enabledDropdown')
</template>
<script>
import customFieldType from "@/types/api-orisis/enums/customFieldType";
import { typeFields } from "@/types/api-orisis/enums/enums";
import { FormValidator } from "@syncfusion/ej2-vue-inputs";

export default {
  data() {
    return {
      data: {},
      customFieldType,
      typeFields,
      showElements: {},
      delayShowElement: 0,
      isChangeTypeField: false,
      enabledDropdown: false,
      fieldToValidate: [],
      formObj: "",
    };
  },
  emits: ["toogleRequiredField"],
  async created() {
    await this.showFieldTypeElement({ value: 1 }, "created").then(() => {
      // this.formObj = new FormValidator('#' + this.$parent.$parent.$el.id, {rules: {
      //   'label': {
      //     required: true,
      //   },
      //   'typeField': {
      //     required: true,
      //   },
      // }})
    });
  },

  methods: {
    async showFieldTypeElement(e, type) {
      this.removeErrorMessage();
      this.delayShowElement = 0;
      this.isChangeTypeField = true;
      const dataArray = await Object.entries(
        JSON.parse(JSON.stringify(customFieldType))
      ).map(([name, value]) => {
        return { name, value };
      });

      // let requiredList = []

      for (let i = 0; i < dataArray.length; i++) {
        const element = dataArray[i];
        if (element.value.typeField === this.data.typeField) {
          this.showElements = element.value;
        }
        // Première partie pour la validation
        // if (e && element.value.typeField === e.value) {
        //     requiredList = Object.entries(structuredClone(element.value)).map(([key, value]) =>{ return {key: this.extractBase(key), value} })
        // }
      }

      // Deuxième partie pour la validation
      // this.$parent.$parent.$refs['overviewgrid'].$el.ej2_instances[0].columnModel.map((column) => {
      //     if (requiredList.some(required => column.field ? column.field.includes(required.key) : false)) {
      //         const valueRequired = requiredList.find(required => required.key === column.field)
      //         column.validationRules = valueRequired.value === true ? {required: valueRequired.value} : undefined
      //     }
      // })

      if (type !== "created") {
        this.data.options = "";
        this.data.defaultValue = null;
      }

      setTimeout(() => {
        this.delayShowElement = this.showElements.typeField;
        this.isChangeTypeField = false;
      }, 500);
    },
    removeErrorMessage() {
      let elementsWithError = document.querySelectorAll('[id*="_Error"]');
      elementsWithError = Array.from(elementsWithError).map(
        (item) => (item.style.display = "none")
      );
    },
    extractBase(key) {
      const index = key.lastIndexOf("Required");
      return key.slice(0, index);
    },
  },
  computed: {
    listOptions() {
      if (this.data.options.includes(";")) {
        this.enabledDropdown = true;
        return this.data.options.split(";");
      } else {
        this.enabledDropdown = false;
        return [];
      }
    },
  },
};
</script>
<style lang="scss"></style>
